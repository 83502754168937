import { DashboardReferralTable } from "../../../components";

const DashboardReferralPage = () => {
  return (
    <div className="p-6">
      <DashboardReferralTable isViewAll={true} />
    </div>
  );
};

export default DashboardReferralPage;
