import { Keyword } from "../entities";
import axiosClient from "./axiosClient";
import { KEYWORD, STORE } from "./urlConfig";

export const KeywordApi = {
  getList: async (params?: object) => {
    const res: any = await axiosClient.get(
      `${KEYWORD}/?fields=["$all"]&limit=500&order=[["index","ASC"],["created_at","DESC"]]`,
      { params },
    );
    return res?.results?.objects?.rows || [];
  },
  updateKeyword: async (id: string, data: Keyword) => {
    return await axiosClient.put(`${KEYWORD}/${id}`, data);
  },
  deleteKeyword: async (id: string) => {
    return await axiosClient.delete(`${KEYWORD}/${id}`);
  },
  createKeyword: async (data: Keyword) => {
    return await axiosClient.post(KEYWORD, data);
  },
  orderKeyword: async (id?: string, data?: any) => {
    return await axiosClient.put(`${KEYWORD}/order-keyword/${id}`, data);
  },
  uploadExcel: async (file: File, keyword_category_id: string) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res: any = await axiosClient.post(
      `${KEYWORD}/import_excel?keyword_category_id=${keyword_category_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return res;
  },
  downloadExcel: (params?: any) => {
    return axiosClient.get(`${KEYWORD}/download_excel`, { params });
  },
};
