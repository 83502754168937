import { CloseOutlined } from '@ant-design/icons';
import { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { classNames } from '../../utils/common';
import CustomButton from '../button';
import BaseText from '../text';
import { Modal } from 'antd';
import BaseButton from '../baseButton';
import "./index.css"

interface BaseModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string | ReactNode;
  width?: any;
  children: ReactNode;
  bodyStyle?: any;
  styleTitle?: string;
  disableSubmitBtn?: boolean;
  nameCancel?: string;
  nameConfirm?: string;
  styleButtonCancel?: string;
  styleButtonConfirm?: string;
  typeButtonConfirm?: 'primary' | 'default' | 'danger';
  isHideAction?: boolean;
  noScroll?: boolean;
  afterOpenChange?: any;
  className?: string;
}

export const BaseModal3 = (props: BaseModalProps) => {
  const {
    isOpen,
    onSubmit,
    onClose,
    width,
    isHideAction,
    title,
    children,
    noScroll,
    bodyStyle,
    disableSubmitBtn,
    nameCancel,
    nameConfirm,
    styleTitle,
    styleButtonCancel,
    styleButtonConfirm,
    typeButtonConfirm,
    afterOpenChange,
    className
  } = props;
  const [isShown, setIsShown] = useState<boolean>(isOpen);

  const closeModal = () => {
    setIsShown(false);
    onClose && onClose();
  };

  const submitModal = () => {
    onSubmit && onSubmit();
    setIsShown(false);
  };

  useEffect(() => {
    setIsShown(isOpen);
  }, [isOpen]);

  return (
    <Modal
      centered
      width={width}
      open={isShown}
      afterOpenChange={afterOpenChange}
      onCancel={() => {
        closeModal();
      }}
      closable={false}
      footer={null}
      className={className}
    >
      <div className='flex items-center justify-between px-6 py-4 bg-white rounded-lg'>
        {typeof title === 'string' ? <BaseText
          bold
          locale
          size={20}
          className={classNames(
            'font-bold text-left text-darkNight900',
            styleTitle
          )}
        >
          {title}
        </BaseText> : title}
      </div>
      <div
        className={classNames(
          'max-h-[80vh] px-2 py-4 ',
          noScroll ? '' : 'overflow-auto',
          bodyStyle
        )}
      >
        {children}
      </div>
      {!isHideAction && (
        <div className='flex gap-4 px-6 py-4 border-t border-darkNight100 sm:px-6'>
          <BaseButton
            onClick={closeModal}
            type='default'
            className={styleButtonCancel}
          >
            {nameCancel || 'Cancel'}
          </BaseButton>
          <BaseButton
            onClick={submitModal}
            className={styleButtonConfirm}
            type={typeButtonConfirm}
            disabled={disableSubmitBtn}
          >
            {nameConfirm || 'Confirm'}
          </BaseButton>
        </div>
      )}
    </Modal>
  );
};
