import { Url } from "../../routers/paths";

export const menuList: Record<string, string> = {
  [Url.dashboard]: Url.dashboard,
  [Url.user]: Url.user,
  [Url.bulletinBoard]: Url.bulletinBoard,
  [Url.store]: Url.store,
  [Url.storeListing]: Url.storeListing,
  [Url.reservationDetails]: Url.reservationDetails,
  [Url.shopping]: Url.shopping,
  [Url.helpCenter]: Url.helpCenter,
  [Url.report]: Url.report,
  // [Url.subcription]: Url.subcription,
  [Url.pointHistory]: Url.pointHistory,
  [Url.blogs]: Url.blogs,
  [Url.appVersion]: Url.appVersion,
  [Url.seo]: Url.seo,
  [Url.setting]: Url.setting,
  [Url.site]: Url.site,
  [Url.leaderBoard]: Url.leaderBoard,
  [Url.keywordSetting]: Url.keywordSetting,
};
