import React, {useEffect, useState} from 'react';
import {BaseEditor} from "../../../components";
import {PopupInterface} from "../../../entities";
import {popupApi} from "../../../apis/popupApi";
import {showError, showSuccess} from "../../../utils/showToast";

interface PopupSettingRightProps {
    isCreate: boolean;
    changeIsCreate: (value: boolean) => void
    data ?: PopupInterface
    changeData: (value: PopupInterface|undefined) => void
    changeRefresh: () => void
}

const PopupSettingRight = ({isCreate , changeIsCreate , data , changeData , changeRefresh }:PopupSettingRightProps) => {
    const [defaultContent, setDefaultContent] = useState(data?.content || '');
    const [content, setContent] = useState('');
    const handleCreate =async () => {
        if (data && data.title && !data.id){
            try {
               const res =  await popupApi.create({...data , content})
                changeData(res)
                showSuccess("Created successfully");
                changeIsCreate(false)
                changeRefresh()
            }
            catch (e){
                showError(e)
            }
        }
    }
    const handleUpdate = async () => {
        if (data && data.id){
            try {
                const res = await popupApi.update(data.id, {
                    ...data,
                    content : content ? content : defaultContent
                })
                changeData(res)
                showSuccess("Updated successfully");
                changeIsCreate(false)
                changeRefresh()
            }
            catch (e){
                showError(e)
            }
        }
    }
    const handleDelete = async () => {
        if (data && data.id){
            try {
                await popupApi.delete(data.id)
                changeData(undefined)
                showSuccess("Deleted successfully");
                changeIsCreate(false)
                changeRefresh()
            }
            catch (e){
                showError(e)
            }
        }
    }
    useEffect(() => {
        if (data){
            setContent(data.content || '')
            setDefaultContent(data.content || '')
        }else {
            setContent('')
            setDefaultContent('')
        }
    }, [data]);
    return (
        <div className='flex-1 flex flex-col'>
            <label htmlFor='title' className='font-bold text-xl mb-3 block w-full'>Title</label>
            <textarea id='title'
                      className='w-full resize-none text-xl p-4 bg-[#F6F6F6] rounded-2xl mb-4'
                      rows={2}
                      value={data?.title || ''}
                      onChange={(e)=>{changeData({...data, title: e.target.value})}}
            />
            <label htmlFor='content' className='font-bold text-xl mb-3 block w-full'>Content</label>
            <BaseEditor
                defaultValue={defaultContent}
                value={content}
                onChange={(value: string) => setContent(value)}
                height={680}
            />
            <div className='mt-3 flex items-center justify-center gap-3'>
                {isCreate ? (<button className='bg-green-500 py-3 px-8 rounded-xl text-white' onClick={handleCreate}>Create</button>) : (
                    <>
                        <button className='bg-[#F5222D] py-3 px-8 rounded-xl text-white' onClick={handleDelete}>Delete</button>
                        <button className='bg-[#0866FF] py-3 px-8 rounded-xl text-white' onClick={handleUpdate}>Update</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PopupSettingRight;