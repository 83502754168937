import axiosClient from './axiosClient'
import { POPUP} from './urlConfig'
import {PopupInterface} from "../entities";

export const popupApi = {
  getList: async () => {
    const res: any = await axiosClient.get(`${POPUP}?fields=["$all"]&limit=50&order=[["index","ASC"],["updated_at","DESC"]]`);
    return res?.results?.objects?.rows || []
  },
  create: async (data: PopupInterface) => {
    const res: any = await axiosClient.post(POPUP, data);
    return res?.results?.object
  },
  update: async (id: string, data: PopupInterface) => {
    const res: any = await axiosClient.put(`${POPUP}/${id}`, data);
    return res?.results?.object
  },
  delete: async (id: string) => {
    return await axiosClient.delete(`${POPUP}/${id}`);
  },
  orderPopup: async (id?: string, data?: any) => {
    return await axiosClient.put(`${POPUP}/order-item/${id}`, data);
  },
}
