import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import "./App.css";
import Router from "./routers";
import { useLocalStorage } from "./stores/localStorage";
import { Spin } from "antd";
import { useCommonState } from "./stores/commonStorage";
import { useSocketContext } from "./contexts/SocketContext";
import { SOCKET_EVENT } from "./utils/constants";

const AppMain: React.FC = () => {
  const { locale, setRefreshToken, setAccessToken, setExpiresIn } =
    useLocalStorage((state) => state);
  const { loading } = useCommonState((state) => state);
  const socket = useSocketContext();
  useEffect(() => {
    i18next.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    function onConnect() {
      console.log("connected");
    }

    function onDisconnect() {
      console.log("disconnected");
    }

    socket?.on("connect", onConnect);
    socket?.on("disconnect", onDisconnect);
    socket?.on(SOCKET_EVENT.LOG_OUT, () => {
      setAccessToken("");
      setRefreshToken("");
      setExpiresIn(0);
      window.location.reload();
    });
    socket?.on("connect_error", (error: any) => {
      console.error("Connect error:", error);
    });

    return () => {
      socket?.off("connect", onConnect);
      socket?.off("disconnect", onDisconnect);
      socket?.off(SOCKET_EVENT.LOG_OUT);
      socket?.off("connect_error");
    };
  }, [socket]);

  return (
    <div className="relative">
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      {loading && (
        <div
          style={{ zIndex: 100000000000 }}
          className="absolute flex justify-center items-center bg-opacity-40 top-0 left-0 w-full h-full  bg-darkNight400"
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default AppMain;
