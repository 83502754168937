import {useState} from "react";
import {Tabs} from "antd";
import TabBarItem from "./component/TabBarItem";
import TabPane from "antd/es/tabs/TabPane";
import BranchIntroduction from "./component/BranchIntroduction";
import CompanyInformation from "./component/CompanyInformation";
import {useTranslation} from "react-i18next";
import QASetting from "./component/QASetting";
import CustomService from "./component/CustomService";
import {RegionAndSubwaySetting} from "./component/RegionAndSubwaySetting";
import PopupSetting from "./component/popupSetting";
import Terms from "./component/Terms";
import PersonalInformation from "./component/PersonalInformation";
import TermsOfLocationInformation from "./component/TermsOfLocationInformation";

const SettingPage = () => {
    const {t} = useTranslation();

    const [activeKey, setActiveKey] = useState("1");

    const handleTabChange = (key: any) => {
        setActiveKey(key);
    };
    return (
        <Tabs activeKey={activeKey} onChange={handleTabChange} className='h-svh'  tabPosition= 'left'>
            <TabPane
                tab={
                    <TabBarItem
                        index="1"
                        activeKey={activeKey}
                        title={t("브랜드 소개")}
                    />
                }
                key="1"
            >
                <BranchIntroduction/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem index="2" activeKey={activeKey} title={t("회사 정보")}/>
                }
                key="2"
            >
                <CompanyInformation/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="3"
                        activeKey={activeKey}
                        title={t("자주 묻는 질문")}
                    />
                }
                key="3"
            >
                <QASetting/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="4"
                        activeKey={activeKey}
                        title={t("고객 서비스")}
                    />
                }
                key="4"
            >
                <CustomService/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="5"
                        activeKey={activeKey}
                        title={t("필터 지역 및 지하철 설정")}
                    />
                }
                key="5"
            >
                <RegionAndSubwaySetting/>
            </TabPane>

            <TabPane
                tab={
                    <TabBarItem
                        index="6"
                        activeKey={activeKey}
                        title={t("popupBoard")}
                    />
                }
                key="6"
            >
                <PopupSetting/>
            </TabPane>

            <TabPane
                tab={
                    <TabBarItem
                        index="7"
                        activeKey={activeKey}
                        title={t("terms")}
                    />
                }
                key="7"
            >
                <Terms/>
            </TabPane>

            <TabPane
                tab={
                    <TabBarItem
                        index="8"
                        activeKey={activeKey}
                        title={t("personal_information_processing_policy")}
                    />
                }
                key="8"
            >
                <PersonalInformation/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="9"
                        activeKey={activeKey}
                        title={t("terms_of_location_information")}
                    />
                }
                key="9"
            >
                <TermsOfLocationInformation/>
            </TabPane>
        </Tabs>


    );
};

export default SettingPage;
