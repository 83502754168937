// socketContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
// @ts-ignore
import io, { Socket } from "socket.io-client";
import { Config } from "../utils/constants";
import { useLocalStorage } from "../stores/localStorage";

const createSocket = (token: string): Socket => {
  console.log("Creating socket with token:", token); // Log để kiểm tra token
  const newSocket = io(Config.SOCKET_API, {
    query: { token },
    transports: ["websocket"],
    withCredentials: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
  });

  newSocket.on("connect_error", (error) => {
    console.error("Socket connection error:", error);
  });

  return newSocket;
};

export const SocketContext = createContext<Socket | null>(null);

type Props = {
  children?: React.ReactNode;
};

export const SocketContextProvider: React.FC<Props> = ({ children }) => {
  const { accessToken } = useLocalStorage((state) => state);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (accessToken) {
      const newSocket = createSocket(accessToken);
      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
