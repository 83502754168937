import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppMain from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { ConfigProvider } from "antd";
import { App } from "antd";
import { SocketContextProvider } from "./contexts/SocketContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: '"Noto Sans KR", sans-serif',
      },
    }}
  >
    <SocketContextProvider>
      <App>
        <Suspense fallback={<div>Loading</div>}>
          <AppMain />
        </Suspense>
      </App>
    </SocketContextProvider>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
