import { KeywordType } from "../entities";
import axiosClient from "./axiosClient";
import { KEYWORD_TYPE } from "./urlConfig";

export const KeywordTypeApi = {
  getList: async (params?: object) => {
    const res: any = await axiosClient.get(
      `${KEYWORD_TYPE}/?fields=["$all",{"category":["$all"]}]&limit=500&order=[["index","ASC"],["created_at","DESC"]]`,
      { params },
    );
    return res?.results?.objects?.rows || [];
  },
  updateKeywordType: async (id: string, data: KeywordType) => {
    return await axiosClient.put(`${KEYWORD_TYPE}/${id}`, data);
  },
  deleteKeywordType: async (id: string) => {
    return await axiosClient.delete(`${KEYWORD_TYPE}/${id}`);
  },
  createKeywordType: async (data: KeywordType) => {
    return await axiosClient.post(KEYWORD_TYPE, data);
  },
  orderKeywordType: async (id?: string, data?: any) => {
    return await axiosClient.put(
      `${KEYWORD_TYPE}/order-keyword-type/${id}`,
      data,
    );
  },
};
