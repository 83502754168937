import React, {useEffect, useState} from 'react';
import {popupApi} from "../../../apis/popupApi";
import { PopupInterface} from "../../../entities";
import {showError} from "../../../utils/showToast";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {classNames} from "../../../utils/common";
import {NEW_ID} from "../../bulletin/viewleft";
interface PopupSettingLeftProps {
    changeIsCreate: (value: boolean) => void
    changeData: (value: PopupInterface | undefined) => void
    lastRefresh : number
}
const PopupSettingLeft = ({changeIsCreate , changeData ,lastRefresh}:PopupSettingLeftProps) => {
    const [popups, setPopups] = useState<Array<PopupInterface>>([])
    const _getPopups= async () => {
        try {
            const res = await popupApi.getList()
            setPopups(res)
        } catch (error) { }
    }
    const orderItem = async (
        prev_index_number: number | undefined,
        next_index_number: number | undefined,
        index: number
    ) => {
        try {
            await popupApi.orderPopup(popups[index].id, {
                prev_index_number,
                next_index_number,
            })
            _getPopups()
        } catch (error) {
            showError(error)
            _getPopups()
        }
    }
    const onDragEnd = (result: any) => {
        try {
            if (!result.destination) {
                return
            }

            if (result.source.index < result.destination.index) {
                orderItem(
                    popups[result.destination.index]?.index,
                    popups[result.destination.index + 1]?.index,
                    result.source.index
                )
            } else {
                orderItem(
                    popups[result.destination.index - 1]?.index,
                    popups[result.destination.index]?.index,
                    result.source.index
                )
            }

            const newItems = [...popups]
            const [reorderedItem] = newItems.splice(result.source.index, 1)
            newItems.splice(result.destination.index, 0, reorderedItem)
            setPopups(newItems)
        } catch (error) { }
    }

    useEffect(() => {
        _getPopups()
    }, [lastRefresh])
    return (
        <div className='w-[224px] border border-[#e7e7e7] flex-col flex'>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppablePopup'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {popups.map((item: PopupInterface, index: number) => {
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id || NEW_ID}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                className={classNames(
                                                    snapshot.isDragging
                                                        ? 'bg-dayBreakBlue50 rounded-lg'
                                                        : ''
                                                )}
                                            >
                                                <div className='py-3 px-4 text-xl font-normal truncate text-[#8A8A8A]' onClick={()=>{
                                                    changeData(item)
                                                    changeIsCreate(false)
                                                }}>
                                                    {item.title}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="px-4 py-3 bg-white justify-center items-center gap-2.5 inline-flex hover:opacity-80 cursor-pointer" onClick={()=>{
                changeIsCreate(true)
                changeData(undefined)
            }}>
                <div
                    className="grow shrink basis-0 text-[#0866ff] text-xl font-medium leading-7">+
                    New
                </div>
            </div>
        </div>
    );
};

export default PopupSettingLeft;