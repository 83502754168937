import { useEffect, PropsWithChildren } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaOptionsType } from "embla-carousel/components/Options";

type CarouselProps = PropsWithChildren &
  EmblaOptionsType & {
    onScroll?: (i: number) => void;
  };

export const Carousel = ({ children, onScroll, ...options }: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    if (onScroll) {
      emblaApi?.on("scroll", () => {
        onScroll(emblaApi.selectedScrollSnap());
      });
      emblaApi?.on("init", () => {
        onScroll(emblaApi.selectedScrollSnap());
      });
    }

    if (options.loop) {
      const autoplayInterval = 3000;
      let intervalId = setInterval(() => {
        if (emblaApi) {
          emblaApi.scrollNext();
        }
      }, autoplayInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [emblaApi]);

  return (
    <div className="overflow-hidden" ref={emblaRef}>
      {children}
    </div>
  );
};
