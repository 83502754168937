import React from 'react';
import BaseText from "../../components/text";
import {classNames} from "../../utils/common";
import ThemaTable from "../bulletin/thema/thema_table";

const ThemaPage = () => {
    return (
        <div>
            {/*<div className='flex items-center justify-between px-6 py-4 bg-white rounded-lg'>*/}
            {/*    <BaseText*/}
            {/*        bold*/}
            {/*        locale*/}
            {/*        size={20}*/}
            {/*        className={classNames(*/}
            {/*            'font-bold text-left text-darkNight900',*/}
            {/*        )}*/}
            {/*    >*/}
            {/*        Thema Management*/}
            {/*    </BaseText>*/}
            {/*</div>*/}
            <ThemaTable/>
        </div>
    );
};

export default ThemaPage;