import { SiteBanner } from "../entities";
import axiosClient from "./axiosClient";
import { SITE_BANNER } from "./urlConfig";

export const SiteBannerApi = {
  getList: async (params?: object) => {
    const res: any = await axiosClient.get(
      `${SITE_BANNER}/?fields=["$all"]&limit=50&order=[["index","ASC"],["created_at","DESC"]]`,
      { params },
    );
    return res?.results?.objects?.rows || [];
  },
  updateSiteBanner: async (id: string, data: Partial<SiteBanner>) => {
    return await axiosClient.put(`${SITE_BANNER}/${id}`, data);
  },
  deleteSiteBanner: async (id: string) => {
    return await axiosClient.delete(`${SITE_BANNER}/${id}`);
  },
  createSiteBanner: async (data: Partial<SiteBanner>) => {
    return await axiosClient.post(SITE_BANNER, data);
  },
  orderSiteBanner: async (id?: string, data?: any) => {
    return await axiosClient.put(
      `${SITE_BANNER}/order-site-banner/${id}`,
      data,
    );
  },
};
