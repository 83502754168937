export const login = "/login";
export const dashboard = "/dashboard";
export const dashboardOverview = "/dashboard/overview";
export const dashboardInflowDomaine = "/dashboard/inflow";
export const dashboardReferral = "/dashboard/referral";
export const dashboardVisit = "/dashboard/visit";
export const dashboardIncoming = "/dashboard/incoming";
export const user = "/user";
export const userDetail = "/user/detail";
export const userActivity = "/user/activity";
export const bulletinBoard = "/bulletin-board";
export const themaBoard = "/thema";
export const store = "/store";
export const newStore = "/store/new-store";
export const storeListing = "/store/store-listing";
export const reservationDetails = "/store/reservation-details";
export const shopping = "/shopping";
export const helpCenter = "/help-center";
export const report = "/report";
export const subcription = "/subcription";
export const pointHistory = "/point-history";
export const blogs = "/blogs";
export const appVersion = "/app-version";
export const seo = "/seo";
export const setting = "/setting";
export const toolbox = "/tool-box";
export const site = "/site";
export const leaderBoard = "/leaderBoard";
export const keywordSetting = "/keyword-setting";
