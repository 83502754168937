import React, {
  forwardRef,
  SVGProps,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as Accordion from "@radix-ui/react-accordion";
import {
  PlusIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@radix-ui/react-icons";
import Images from "../../../assets/gen";
import { classNames } from "../../../utils/common";
import {
  Keyword,
  KeywordCategory as KeywordCategoryInterface,
} from "../../../entities";
import { KeywordApi } from "../../../apis/keywordApi";
import { KeywordCategoryApi } from "../../../apis/keywordCategoryApi";
import { App, notification, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { BaseModal2 } from "../../../components/modal/BaseModal2";
import { BaseInput } from "../../../components/input/BaseInput";
import { BaseInputSelect } from "../../../components/input/BaseInputSelect";
import { ModalSelectRegion } from "../../newStore/components/ModalSelectRegion";
import { storeApi } from "../../../apis/storeApi";
import { showError } from "../../../utils/showToast";

interface KeywordCategoryProps {
  category: KeywordCategoryInterface;
  keywordTypeId: string | undefined;
  getListKeywordCategory: () => void;
  nextIndex?: number;
  prevIndex?: number;
  next2Index?: number;
  prev2Index?: number;
  openModalCreateKeywordCategory: boolean;
  setOpenModalCreateKeywordCategory: (value: boolean) => void;
  changeKeywordCategory: (value: string) => void;
  nameKeywordCategory: string;
  idEditKeywordCategory: string | undefined;
  setIdEditKeywordCategory: (value: string | undefined) => void;
  categoryId?: string;
}

function VscodeIconsFileTypeExcel2(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="#20744a"
        fillRule="evenodd"
        d="M28.781 4.405h-10.13V2.018L2 4.588v22.527l16.651 2.868v-3.538h10.13A1.16 1.16 0 0 0 30 25.349V5.5a1.16 1.16 0 0 0-1.219-1.095m.16 21.126H18.617l-.017-1.889h2.487v-2.2h-2.506l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2h10.411Z"
      ></path>
      <path
        fill="#20744a"
        d="M22.487 7.439h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m6.347 10.673l2.146-.123l1.349 3.709l1.594-3.862l2.146-.123l-2.606 5.266l2.606 5.279l-2.269-.153l-1.532-4.024l-1.533 3.871l-2.085-.184l2.422-4.663z"
      ></path>
    </svg>
  );
}

const KeywordCategory = forwardRef((props: KeywordCategoryProps, ref) => {
  const {
    category,
    keywordTypeId,
    getListKeywordCategory,
    prevIndex,
    nextIndex,
    prev2Index,
    next2Index,
    setOpenModalCreateKeywordCategory,
    openModalCreateKeywordCategory,
    nameKeywordCategory,
    changeKeywordCategory,
    idEditKeywordCategory,
    setIdEditKeywordCategory,
    categoryId,
  } = props;
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [listKeyword, setListKeyword] = useState<Keyword[]>([]);
  const [nameKeyword, setNameKeyword] = useState("");
  const [district, setDistrict] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [province, setProvince] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [openModalRegion, setOpenModalRegion] = useState<boolean>(false);
  const [isEditKeyword, setIsEditKeyword] = useState<boolean>(false);
  const [openModalCreateKeyword, setOpenModalCreateKeyword] = useState(false);
  const [keywordEdit, setKeywordEdit] = useState<Keyword | undefined>(
    undefined,
  );
  const [openM, setOpenM] = useState(false);

  const fileExcelRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    changeOpenM(value: boolean) {
      setOpenM(value);
    },
    resetProvince() {
      setProvince(undefined);
    },
    resetDistrict() {
      setDistrict(undefined);
    },
  }));
  //keywordCategory
  const handleCreateKeywordCategory = async () => {
    if (nameKeywordCategory && keywordTypeId) {
      try {
        const data = {
          name: nameKeywordCategory,
          keyword_type_id: keywordTypeId,
          district_id: district?.id,
          province_id: province?.id,
        };
        await KeywordCategoryApi.createKeywordCategory(data);
        await getListKeywordCategory();
        message.success("Create successfully");
      } catch (e) {
        message.error("Create failed");
      }
    }
    changeKeywordCategory("");
  };
  const handleDeleteKeywordCategory = async (id: string) => {
    try {
      await KeywordCategoryApi.deleteKeywordCategory(id);
      await getListKeywordCategory();
      message.success("Delete successfully");
    } catch (e) {
      message.error("Delete failed");
    }
  };
  const handleUpdateKeywordCategory = async (
    id: string,
    data: KeywordCategoryInterface,
  ) => {
    try {
      await KeywordCategoryApi.updateKeywordCategory(id, data);
      await getListKeywordCategory();
      message.success("Update successfully");
    } catch (e) {
      message.error("Update failed");
    }
  };
  const handleOrderKeywordCategory = async (
    id: string,
    type: "up" | "down",
  ) => {
    if (type === "up" && prevIndex) {
      await KeywordCategoryApi.orderKeywordCategory(id, {
        prev_index_number: prev2Index,
        next_index_number: prevIndex ? prevIndex : category.index,
      });
    }
    if (type === "down" && nextIndex) {
      await KeywordCategoryApi.orderKeywordCategory(id, {
        next_index_number: next2Index,
        prev_index_number: nextIndex ? nextIndex : category.index,
      });
    }
    getListKeywordCategory();
  };
  //keyword
  const handleCreateKeyword = async () => {
    try {
      if (nameKeyword && category.id) {
        const data = {
          name: nameKeyword,
          keyword_category_id: category.id,
        };
        await KeywordApi.createKeyword(data);
        await getListKeyword();
        message.success("Create successfully");
      }
    } catch (e) {
      message.error("Create failed");
    }
  };
  const handleDeleteKeyword = async (id: string) => {
    try {
      await KeywordApi.deleteKeyword(id);
      await getListKeyword();
      message.success("Delete successfully");
    } catch (e) {
      message.error("Delete failed");
    }
  };
  const handleUpdateKeyword = async (data: any) => {
    try {
      if (keywordEdit) await KeywordApi.updateKeyword(keywordEdit.id!, data);
      await getListKeyword();
      message.success("Update successfully");
    } catch (e) {
      message.error("Update failed");
    }
  };
  const handleOrderKeyword = async (
    id: string,
    type: "up" | "down",
    prevIndex?: number,
    nextIndex?: number,
    prev2Index?: number,
    next2Index?: number,
  ) => {
    if (type === "up" && prevIndex) {
      await KeywordApi.orderKeyword(id, {
        prev_index_number: prev2Index,
        next_index_number: prevIndex ? prevIndex : category.index,
      });
    }
    if (type === "down" && nextIndex) {
      await KeywordApi.orderKeyword(id, {
        next_index_number: next2Index,
        prev_index_number: nextIndex ? nextIndex : category.index,
      });
    }
    getListKeyword();
  };
  const getListKeyword = async () => {
    try {
      const res = await KeywordApi.getList({
        filter: JSON.stringify({ keyword_category_id: category.id }),
      });
      setListKeyword(res);
    } catch (e) {
      console.log("error", e);
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        const response: any = await KeywordApi.uploadExcel(file, category.id!);
        if (response.code === 200) {
          notification.success({
            message: "Upload Excell Success",
          });
        }
      }
      getListKeyword();
    } catch (error) {
      showError(error);
    }
  };

  const handleSubmitRegion = (value: {
    province: { id: string; name: string };
    district: { id: string; name: string };
  }) => {
    setProvince(value.province);
    setDistrict(value.district);
    setOpenModalRegion(false);
    try {
      handleUpdateKeywordCategory(category.id!, {
        ...category,
        province_id: value.province.id,
        district_id: value.district.id,
      }).then();
      message.success("Update Region successfully");
    } catch (e) {
      message.error("Update Region failed");
    }
  };
  //useEffect
  useEffect(() => {
    getListKeyword().then();
    if (category.province && !province) {
      setProvince({ id: category.province.id, name: category.province.name });
    }
    if (category.district && !district) {
      setDistrict({ id: category.district.id, name: category.district.name });
    }
  }, [categoryId]);
  if (!categoryId) {
    return (
      <BaseModal2
        isOpen={!!openM}
        onClose={() => {
          changeKeywordCategory("");
          setOpenM(false);
          setIdEditKeywordCategory(undefined);
        }}
        onSubmit={() => {
          handleCreateKeywordCategory();
          setOpenM(false);
          setIdEditKeywordCategory(undefined);
        }}
      >
        <div className="flex flex-col p-6 gap-6">
          <BaseInput
            title="Name"
            // placeholder="Please enter name"
            value={nameKeywordCategory}
            onChange={(value) => changeKeywordCategory(value)}
          />
        </div>
      </BaseModal2>
    );
  }

  return (
    <Accordion.Root
      type="multiple"
      className="w-full border rounded-xl mb-5 shadow-md"
      onValueChange={(value) => setIsOpen(value.includes("item-1"))}
    >
      <Accordion.Item value="item-1">
        <Accordion.Header className="flex items-center p-4 bg-white rounded-xl">
          <Accordion.Trigger
            className={classNames(
              "flex items-center w-full text-left text-2xl font-medium text-black",
              isOpen ? "gap-5" : "justify-between",
            )}
          >
            <div>{category.name}</div>
            {isOpen && (
              <>
                <div
                  className="p-2 text-base flex-1 border border-gray-300 rounded-lg text-gray-600 bg-[#F6F6F6]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModalRegion(true);
                  }}
                >
                  {province
                    ? province.name + " " + district?.name
                    : "지역을 선택해 주세요"}
                </div>
              </>
            )}
            <div className="flex items-center space-x-2 justify-between gap-2 lg:gap-[10px] xl:gap-10 2xl:gap-[50px]">
              {/* Chỉ hiển thị khi accordion mở */}

              {isOpen && (
                <>
                  <VscodeIconsFileTypeExcel2
                    className="w-7 h-7 text-blue-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      fileExcelRef?.current?.click();
                    }}
                  />
                  <input
                    onClick={(e) => e.stopPropagation()}
                    ref={fileExcelRef}
                    onChange={handleFileChange}
                    id="fileExcel"
                    type="file"
                    className="hidden"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <PlusIcon
                    className="w-7 h-7 text-blue-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModalCreateKeyword(true);
                    }}
                  />
                  <img
                    src={Images.pen}
                    className="w-7 h-7 text-blue-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeKeywordCategory(category.name);
                      setOpenM(true);
                      setIdEditKeywordCategory(category.id);
                    }}
                  />
                  <ArrowUpIcon
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      handleOrderKeywordCategory(category.id!, "up")
                    }
                  />
                  <ArrowDownIcon
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      handleOrderKeywordCategory(category.id!, "down")
                    }
                  />
                  <Popconfirm
                    onConfirm={(e: any) => {
                      e.stopPropagation();
                      handleDeleteKeywordCategory(category.id!);
                    }}
                    title={t("Delete")}
                    description={t("Are you sure to delete")}
                    placement="left"
                  >
                    <img
                      src={Images.trash}
                      className="w-7 h-7 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                </>
              )}
              {isOpen ? (
                <div className="w-7 h-7 border rounded-full flex justify-center items-center">
                  <ChevronUpIcon className="w-5 h-5 cursor-pointer text-blue-500" />
                </div>
              ) : (
                <div className="w-7 h-7 border rounded-full flex justify-center items-center">
                  <ChevronDownIcon className="w-5 h-5 cursor-pointer text-blue-500" />
                </div>
              )}
            </div>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className="bg-white p-4">
          {listKeyword.map((item, index) => (
            <div key={index} className="flex justify-between items-center py-2">
              <span className="text-lg font-medium">{item.name}</span>
              <div className="flex gap-[30px]">
                <img
                  src={Images.pen}
                  className="w-7 h-7 text-blue-500 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setNameKeyword(item.name);
                    setKeywordEdit(item);
                    setOpenModalCreateKeyword(true);
                    setIsEditKeyword(true);
                  }}
                />
                <ArrowUpIcon
                  className="w-7 h-7 cursor-pointer"
                  onClick={() =>
                    handleOrderKeyword(
                      item.id!,
                      "up",
                      listKeyword[index - 1]
                        ? listKeyword[index - 1].index
                        : undefined,
                      listKeyword[index + 1]
                        ? listKeyword[index + 1].index
                        : undefined,
                      listKeyword[index - 2]
                        ? listKeyword[index - 2].index
                        : undefined,
                      listKeyword[index + 2]
                        ? listKeyword[index + 2].index
                        : undefined,
                    )
                  }
                />
                <ArrowDownIcon
                  className="w-7 h-7 cursor-pointer"
                  onClick={() =>
                    handleOrderKeyword(
                      item.id!,
                      "down",
                      listKeyword[index - 1]
                        ? listKeyword[index - 1].index
                        : undefined,
                      listKeyword[index + 1]
                        ? listKeyword[index + 1].index
                        : undefined,
                      listKeyword[index - 2]
                        ? listKeyword[index - 2].index
                        : undefined,
                      listKeyword[index + 2]
                        ? listKeyword[index + 2].index
                        : undefined,
                    )
                  }
                />
                <Popconfirm
                  onConfirm={() => handleDeleteKeyword(item.id!)}
                  title={t("Delete")}
                  description={t("Are you sure to delete")}
                  placement="left"
                >
                  <img src={Images.trash} className="w-7 h-7 cursor-pointer" />
                </Popconfirm>
              </div>
            </div>
          ))}
        </Accordion.Content>
      </Accordion.Item>
      {/*modal keyword category*/}
      {!!openM && (
        <BaseModal2
          isOpen={!!openM}
          onClose={() => {
            changeKeywordCategory("");
            setOpenM(false);
            setIdEditKeywordCategory(undefined);
          }}
          onSubmit={() => {
            if (idEditKeywordCategory) {
              handleUpdateKeywordCategory(idEditKeywordCategory, {
                ...category,
                name: nameKeywordCategory,
              }).then();
            } else {
              handleCreateKeywordCategory();
            }
            setOpenM(false);
            setIdEditKeywordCategory(undefined);
          }}
        >
          <div className="flex flex-col p-6 gap-6">
            <BaseInput
              title="Name"
              // placeholder="Please enter name"
              value={nameKeywordCategory}
              onChange={(value) => changeKeywordCategory(value)}
            />
          </div>
        </BaseModal2>
      )}
      {/*modal region*/}
      <ModalSelectRegion
        isAddressKor={true}
        isOpen={openModalRegion}
        onClose={() => {
          setOpenModalRegion(false);
        }}
        onSubmit2={(value) => handleSubmitRegion(value)}
        dataProvince={province?.name}
        dataDistrict={district?.name}
      />
      {/*    modal keyword*/}
      <BaseModal2
        isOpen={!!openModalCreateKeyword}
        onClose={() => {
          setNameKeyword("");
          setOpenModalCreateKeyword(false);
          setIsEditKeyword(false);
          setKeywordEdit(undefined);
          setNameKeyword("");
        }}
        onSubmit={() => {
          if (isEditKeyword) {
            handleUpdateKeyword({
              name: nameKeyword,
            }).then();
          } else {
            handleCreateKeyword();
          }
          setOpenModalCreateKeyword(false);
          setIsEditKeyword(false);
          setKeywordEdit(undefined);
          setNameKeyword("");
        }}
      >
        <div className="flex flex-col p-6 gap-6">
          <BaseInput
            title="Name"
            placeholder="Please enter name"
            value={nameKeyword}
            onChange={(value) => setNameKeyword(value)}
          />
        </div>
      </BaseModal2>
    </Accordion.Root>
  );
});

export default KeywordCategory;
