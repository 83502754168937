import { DashboardInflowDomaineTable } from "../../../components";

const DashboardInflowPage = () => {
  return (
    <div className="p-6">
      <DashboardInflowDomaineTable isViewAll={true} />
    </div>
  );
};

export default DashboardInflowPage;
