import {Tabs} from "antd";
// import TabPane from "antd/es/tabs/TabPane";
import {useTranslation} from "react-i18next";
import {BaseText} from "../../components";
import {useEffect, useState} from "react";
import {classNames} from "../../utils/common";
import TabBarItem from "./component/TabBarItem";
import TabPane from "antd/es/tabs/TabPane";
import PointProduct from "./component/PointProduct";
import ReceivePoint from "./component/ReceivePoint";
import OrderHistory from "./component/OrderHistory";
import {pointHistoryApi} from "../../apis/pointHistoryApi";

export default function PointHistoryPage() {
    const [activeKey, setActiveKey] = useState("1");
    const [count, setCount] = useState({
        pointCount: 0,
        pointProductCount: 0,
        pointProductHistoryCount: 0,
    });
    const handleTabChange = (key: any) => {
        setActiveKey(key);
    };
    const getCount = async (param = {}) => {
       const filter = JSON.stringify(param);
        try {
            const [pointCount, pointProductCount, pointProductHistoryCount] :[any,any,any] = await Promise.all([
                pointHistoryApi.countPoint(filter),
                pointHistoryApi.countPointProduct(filter),
                pointHistoryApi.countPointProductHistory(filter)
            ])
            setCount({
                pointCount: pointCount.results.object.total,
                pointProductCount: pointProductCount.results.object.total,
                pointProductHistoryCount: pointProductHistoryCount.results.object.total,
            });
        }
        catch (e){

        }
    }
    useEffect(() => {
        getCount();
    }, []);

    return (
        <Tabs activeKey={activeKey} onChange={handleTabChange}>
            <TabPane
                tab={
                    <TabBarItem
                        index="1"
                        activeKey={activeKey}
                        title="포인트 상품"
                        count={count.pointProductCount.toString()}
                    />
                }
                key="1"
            >
                <PointProduct/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="2"
                        activeKey={activeKey}
                        title="포인트 획득 내역"
                        count={count.pointCount.toString()}
                    />
                }
                key="2"
            >
                <ReceivePoint/>
            </TabPane>
            <TabPane
                tab={
                    <TabBarItem
                        index="3"
                        activeKey={activeKey}
                        title="상품 신청 내역"
                        count={count.pointProductHistoryCount.toString()}
                    />
                }
                key="3"
            >
                <OrderHistory/>
            </TabPane>
        </Tabs>
    );
}
