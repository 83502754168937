import React, {useCallback, useState} from 'react';
import PopupSettingLeft from "./popupSettingLeft";
import PopupSettingRight from "./popupSettingRight";
import Images from "../../../assets/gen";
import {BaseText} from "../../../components";
import {PopupInterface} from "../../../entities";

const PopupSetting = () => {
    const [isCreate, setIsCreate] = useState(false);
    const [data, setData] = useState<PopupInterface>()
    const [lastRefresh, setLastRefresh] = useState(Date.now());
    const changeIsCreate = useCallback((value: boolean) => {
        setIsCreate(value);
    }, []);
    const changeData = useCallback((value: PopupInterface | undefined) => {
        setData(value);
    }, []);
    const changeRefresh = useCallback(() => {
        setLastRefresh(Date.now());
    } , [])

    return (
        <div className='p-6 flex gap-8 h-[966px]'>
            <PopupSettingLeft changeIsCreate={changeIsCreate} changeData={changeData} lastRefresh={lastRefresh}/>
            {isCreate || data ? (
                <PopupSettingRight
                    isCreate={isCreate}
                    changeIsCreate={changeIsCreate}
                    data={data}
                    changeData={changeData}
                    changeRefresh={changeRefresh}
                />
            ) : (
                <div className='flex flex-col gap-2 flex-1 justify-center items-center'>
                    <img src={Images.dataEmpty} alt="avatar" className="w-32 h-32 rounded-full"/>
                    <BaseText locale>No data</BaseText>
                </div>
            )
            }

        </div>
    );
};

export default PopupSetting;